import React from 'react';
import { Helmet } from 'react-helmet';
import GlobalStyles from '../src/style/GlobalStyles'; // Global stilleri import edin
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Landing from './screens/Landing';
import Dashboard from './screens/Dashboard';
import AppPage1 from './screens/Apps/Qplan/Qplan'; // Uygulama1 sayfası

export default function App() {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=LXGW+WenKai+TC&display=swap" rel="stylesheet" />
      </Helmet>
      <GlobalStyles /> {/* Global stilleri uygulayın */}
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/:sirketKisaAdi/dashboard" element={<Dashboard />} />
          <Route path="/:sirketKisaAdi/dashboard/qplan" element={<AppPage1 />} /> {/* Uygulama1 sayfası */}
          {/* Diğer route'larınızı burada tanımlayın */}
        </Routes>
      </Router>
    </>
  );
}
