import React from 'react';
import styled from 'styled-components';
import { Modal, Button, Form, Input, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Pattern from '../../../src/assets/img/4909528.jpg';
import FullButton from '../Buttons/FullButton';

const LoginModal = ({ isVisible, onClose }) => {
  const navigate = useNavigate();
  // const host = process.env.REACT_APP_MODE === 'development' ? "http://localhost:5000/api/" : "http://localhost:5000/api/";
  const host = process.env.REACT_APP_MODE === 'development' ? "https://hepatechlabs.com/api/" : "https://hepatechlabs.com/api/";

  const onFinish = async (values) => {
    try {
      const response = await axios.post(`${host}auth/login`, values);
      localStorage.setItem('token', response.data.token);
      const sirketKisaAdi = response.data.user.sirket_kisa_adi;
      message.success('Başarıyla giriş yapıldı!');
      navigate(`/${sirketKisaAdi}/dashboard`);
      onClose();
    } catch (error) {
      console.error('Failed to login', error);
      message.error('Giriş başarısız. Lütfen kullanıcı adı ve şifrenizi kontrol edin.');
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <StyledModal visible={isVisible} footer={null} onCancel={onClose} centered>
      <BackgroundPattern />
      <Header>
        <Logo>Qbitech</Logo>
      </Header>
      <Title>Giriş Yap</Title>
      <FormContainer>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Lütfen kullanıcı adınızı girin!' }]}
          >
            <StyledInput
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,0.45)' }} />}
              placeholder="Kullanıcı Adı"
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Lütfen şifrenizi girin!' }]}
          >
            <StyledInput
              type="password"
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,0.45)' }} />}
              placeholder="Şifre"
            />
          </Form.Item>

          <Form.Item>
            <FullButton title={"Giriş Yap"} htmlType="submit">Giriş Yap</FullButton>
          </Form.Item>
        </Form>
      </FormContainer>
      <Footer>Tüm hakları saklıdır © 2024</Footer>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .ant-modal-content {
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    animation: zoomIn 0.3s;
    position: relative;
    z-index: 1;
  }

  @keyframes zoomIn {
    from {
      transform: scale(0.8);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

const Header = styled.div`
  position: absolute;
  top: 10px;
  left: 20px;
`;

const Logo = styled.h1`
  font-family: 'LXGW WenKai TC', sans-serif;
  font-size: 14px;
  color: #333;
  font-weight: bold;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  color: #333;
  font-family: 'LXGW WenKai TC', sans-serif;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
`;

const FormContainer = styled.div`
  width: 100%;
`;

const StyledInput = styled(Input)`
  border: none;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 0;
  padding: 10px 0;
  background: none;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.75);

  &:focus {
    border-bottom-color: black;
    box-shadow: none;
  }

  &:hover {
    border-bottom-color: black;
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.45);
  }
`;

const Footer = styled.div`
  margin-top: 20px;
  text-align: center;
  color: #888;
  font-size: 14px;
`;

const BackgroundPattern = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(${Pattern}) no-repeat center center;
  background-size: cover;
  opacity: 0.1;
  z-index: 0;
`;

export default LoginModal;
