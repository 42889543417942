import React, { useState } from 'react';
import styled from 'styled-components';
import { Layout, Card, Row, Col, Carousel, Button, Modal, message } from 'antd';
import { UserOutlined, DownloadOutlined } from '@ant-design/icons';
import Sidebar from '../components/Sidebar/Sidebar';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { useNavigate } from 'react-router-dom';
import foto1 from '../../src/assets/img/logo-qplan.png';
import foto2 from '../../src/assets/img/qrpfoto2.png';
import foto3 from '../../src/assets/img/qrpfoto3.png';
import foto4 from '../../src/assets/img/qrpfoto4.png';

const { Content } = Layout;

const applications = [
  { id: 1, title: 'Uygulama 1', description: 'Açıklama 1', version: 'v1.0', images: [foto1] },
  { id: 2, title: 'Uygulama 2', description: 'Açıklama 2', version: 'v1.1', images: [foto2] },
  { id: 3, title: 'Uygulama 3', description: 'Açıklama 3', version: 'v1.2', images: [foto3] },
  { id: 4, title: 'Uygulama 4', description: 'Açıklama 4', version: 'v1.3', images: [foto4] },
  { id: 5, title: 'Uygulama 5', description: 'Açıklama 5', version: 'v1.4', images: [] },
  { id: 6, title: 'Uygulama 6', description: 'Açıklama 6', version: 'v1.5', images: [] },
];

const Dashboard = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const navigate = useNavigate();

  const showModal = (image) => {
    setCurrentImage(image);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleLogin = (appId) => {

    if (appId === 1) {
      window.open('https://qplan.hepatechlabs.com', '_blank');


    }
    if (appId === 2) {
      window.open('https://qnter.hepatechlabs.com', '_blank');


    }
    if (appId === 3) {
      window.open('https://www.qdocs.hepatechlabs.com', '_blank');


    }
  };
  return (
    <LayoutStyled>
      <Sidebar />
      <ContentStyled>
        <Row gutter={[36, 36]}>
          {applications.map(app => (
            <Col xs={24} sm={12} md={8} lg={6} key={app.id}>
              <StyledCard
                hoverable
                cover={
                  <StyledCarousel autoplay>
                    {app.images.map((image, index) => (
                      <div key={index} onClick={() => showModal(image)}>
                        <Zoom>
                          <StyledImage style={{width:'100%',height:'65%'}} src={image} alt={`slide-${index}`} />
                        </Zoom>
                      </div>
                    ))}
                  </StyledCarousel>
                }
              >
                <Card.Meta title={app.title} description={app.description} />
                <ButtonGroup>
                  <StyledButton icon={<UserOutlined />} onClick={() => handleLogin(app.id)}>Giriş Yap</StyledButton>
                  <StyledButton type="default" icon={<DownloadOutlined />}>İndir</StyledButton>
                </ButtonGroup>
                <VersionInfo>Versiyon: {app.version}</VersionInfo>
              </StyledCard>
            </Col>
          ))}
        </Row>
        <Modal visible={isModalVisible} footer={null} onCancel={handleCancel}>
          <Zoom>
            <img src={currentImage} alt="Current" style={{ width: '100%' }} />
          </Zoom>
        </Modal>
      </ContentStyled>
    </LayoutStyled>
  );
};

const LayoutStyled = styled(Layout)`
  min-height: 100vh;
  background: #f0f2f5;
`;

const ContentStyled = styled(Content)`
  margin-left: 200px; /* Sidebar genişliğine göre ayarlanır */
  padding: 50px;
  @media (max-width: 768px) {
    margin-left: 0;
    padding: 20px;
  }
`;

const StyledCard = styled(Card)`
  margin-bottom: 20px;
  transition: transform 0.2s;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:hover {
    transform: scale(1.05);
  }
`;

const StyledCarousel = styled(Carousel)`
  img {
    width: 100%;
    height: 200px; /* Resimlerin yüksekliği kart boyutuna göre ayarlanır */
    object-fit: cover;
    cursor: pointer;
    @media (max-width: 768px) {
      height: 150px;
    }
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 200px; /* Resimlerin yüksekliği kart boyutuna göre ayarlanır */
  object-fit: cover;
  object-position: center; /* Resimleri merkezde konumlandırır */
  @media (max-width: 768px) {
    height: 150px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px; /* Butonlar arasındaki boşluğu ayarlar */
  margin: 20px 0; /* Butonların üst ve alt boşluğunu ayarlar */
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const StyledButton = styled(Button)`
  width: 120px;
  height: 30px;
  font-size: 14px;
`;

const VersionInfo = styled.div`
  padding: 10px;
  font-size: 14px;
  color: #555;
  text-align: center;
  margin-top: auto;
`;

export default Dashboard;
