import React, { useState } from 'react';
import { Layout, Menu, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  HomeOutlined,
  AppstoreOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import Swal from 'sweetalert2';

const { Sider } = Layout;

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    Swal.fire({
      title: 'Emin misiniz?',
      text: "Çıkış yapmak istediğinize emin misiniz?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7620ff',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Evet, çıkış yap!',
      cancelButtonText: 'İptal',
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        navigate('/');
      }
    });
  };

  return (
    <SiderStyled collapsible collapsed={collapsed} onCollapse={() => setCollapsed(!collapsed)}>
      <Logo>
        <LogoText collapsed={collapsed}>HepaTechLabs</LogoText>
      </Logo>
      <MenuStyled theme="dark" mode="inline">
        <Menu.Item key="1" icon={<HomeOutlined />}>
          Ana Sayfa
        </Menu.Item>
        <Menu.Item key="2" icon={<AppstoreOutlined />}>
          Uygulamalar
        </Menu.Item>
        <Menu.Item key="3" icon={<LogoutOutlined />}>
          <Button type="link" onClick={handleLogout} style={{ color: 'white' }}>
            Çıkış Yap
          </Button>
        </Menu.Item>
      </MenuStyled>
    </SiderStyled>
  );
};

const SiderStyled = styled(Sider)`
  position: relative; /* Yan çubuğu sabitler */
  left: 0;
  overflow: auto;
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .ant-menu-dark .ant-menu-item-selected {
    background-color: #1890ff;
  }
`;

const Logo = styled.div`
  height: 64px;
  margin: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: white;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
`;

const LogoText = styled.div`
  transition: opacity 0.3s ease;
  opacity: ${({ collapsed }) => (collapsed ? 0 : 1)};
`;

const MenuStyled = styled(Menu)`
  flex-grow: 1;
  .ant-menu-item {
    transition: background-color 0.3s, color 0.3s;
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      color: #1890ff;
    }
  }
`;

export default Sidebar;
