import React, { useEffect } from 'react';

const AppPage1 = () => {
  useEffect(() => {
    window.location.href = 'http://178.16.130.28:4000'; // AppPage1 uygulamasının çalıştığı port
  }, []);

  return (
    <div>
      Qplan'a yönlendiriliyor...
    </div>
  );
};

export default AppPage1;
