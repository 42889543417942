import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    font-family: 'LXGW WenKai TC', sans-serif;
  }

  .swal2-popup {
    font-family: 'LXGW WenKai TC', sans-serif !important;
  }

  .swal2-styled {
    font-family: 'LXGW WenKai TC', sans-serif !important;
  }
`;

export default GlobalStyles;
